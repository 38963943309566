import React from 'react'
import styled from 'styled-components'

const PageTitle = styled.div`
  /* width: 100%;
  font-size: 22px;
  margin-bottom: 10px; */
  font-family: 'Alegreya Sans', --apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
`
export default PageTitle
