import React from 'react'
import styled from 'styled-components'
import WhiteLogo from '../images/logo-white.png'
import Logo from './logo'

const HeaderContainer = styled.div`
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 80px;
  background: url("data:image/svg+xml,%3Csvg width='1500' height='161' viewBox='0 0 1500 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.25 85.7083L0 70.125V161H1500V0L1463.75 19.4792C1428.75 38.9583 1357.5 77.9167 1286.25 97.3958C1213.75 116.875 1142.5 116.875 1071.25 112.979C1035.62 111.031 1000 108.109 964.375 105.188C928.75 102.266 893.125 99.3438 857.5 97.3958C786.25 93.5 713.75 93.5 642.5 101.292C595 106.486 547.5 115.144 500 123.801C476.25 128.13 452.5 132.458 428.75 136.354C357.5 148.042 286.25 155.833 213.75 144.146C142.5 132.458 71.25 101.292 36.25 85.7083Z' fill='white'/%3E%3C/svg%3E%0A"),
    url("data:image/svg+xml,%3Csvg width='623' height='591' viewBox='0 0 623 591' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M355.215 -171.88C430.96 -174.736 498.546 -126.276 555.251 -75.9647C607.763 -29.375 633.165 35.6537 661.517 99.8814C693.545 172.439 747.989 244.053 730.68 321.455C712.973 400.635 638.308 451.132 572.394 498.424C506.864 545.441 435.82 588.282 355.215 590.845C273.066 593.456 192.674 562.994 127.768 512.559C64.2672 463.216 2.42572 395.255 0.0438144 314.859C-2.23668 237.887 85.1299 192.207 116.189 121.746C142.325 62.4528 124.939 -11.0297 165.845 -61.2795C213.156 -119.398 280.338 -169.056 355.215 -171.88Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='367' y1='-172' x2='367' y2='591' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230094E7'/%3E%3Cstop offset='1' stop-color='%230097EC' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A"),
    linear-gradient(180deg, #00a6da 0%, #3fbaff 100%);
  background-repeat: no-repeat;
  background-position: 50% calc(100% + 5px), calc(100% + 200px) 75%, center;
  background-size: cover, 600px auto, 100%;
  width: 100%;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    background-position: 50% calc(100% + 5px), calc(100% + 100px) 75%, center;
    background-size: cover, 300px auto, 100%;
  }
`

const Header = (): JSX.Element => {
  return (
    <HeaderContainer>
      <Logo logoStyle="white" height={60} />
    </HeaderContainer>
  )
}

export default Header
