import React from 'react'
import { preferredLanguage, defaultLanguage } from './../../lib/site-language'
import { navigate } from 'gatsby'
import BaseLayout from '../../components/base'
import Footer from '../../components/footer'
import Header from '../../components/header'
import styled from 'styled-components'
import PageTitle from '../../components/page-title'

const Main = styled.div`
  margin: 20px 40px;

  @media screen and (max-width: 400px) {
    margin: 20px 10px;
  }
`

const PrivacyPolicyPage = (): JSX.Element => {
  React.useEffect(() => {
    const availablePages = ['en', 'id']
    const language = preferredLanguage()
    const page = availablePages.find((p) => p === language) ?? defaultLanguage
    navigate(`/privacy-policy/${page}`)
  })

  return (
    <BaseLayout>
      <Header />
      <Main>
        <PageTitle>Privacy Policy</PageTitle>
        <div>
          Loading privacy policy page based on your language preference...
        </div>
      </Main>
      <Footer />
    </BaseLayout>
  )
}

export default PrivacyPolicyPage
